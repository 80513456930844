import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import './index.css'

import face_shield_icon from '../../assets/img/face_shield.png'
import hand_sewn_mask_icon from '../../assets/img/hand_sewn_mask.png'
import surgical_mask_icon from '../../assets/img/surgical_mask.png'
import gloves_icon from '../../assets/img/gloves.png'
import hand_sanitizer_icon from '../../assets/img/hand_sanitizer.png'
import AOS from 'aos'
import 'aos/dist/aos.css'

function SuppliesTotals ({ items }) {

  // items prints out in a random order, we have no idea why just go with it
  // for (let i = 0; i < items.length; ++i) {
  //   const item = items[i].data
  //   console.log(i)
  //   console.log(item.Items) // this tells you what index to use for what field
  // }

  return (
    <div>
      <h1 className='title pt-6' data-aos='fade-right' data-aos-duration='1000'>Supplies Inventory Breakdown</h1>
      <div className='is-flex center'>
        <div className='card delivery-breakdown-card px-5 py-4 mx-2 mb-6'>
          <img className='image-ppe' src={face_shield_icon} alt='Face Shield' />
          <p className='title'>{items[10].data.In_inventory}</p>
          <p className='subtitle is-7'>Face Shields</p>
        </div>
        <div className='card delivery-breakdown-card px-5 py-4 mx-2 mb-6'>
          <img className='image-ppe' src={surgical_mask_icon} alt='Surgical Mask' />
          <p className='title'>{items[8].data.In_inventory}</p>
          <p className='subtitle is-7'>Surgical Masks</p>
        </div>
        <div className='card delivery-breakdown-card px-5 py-4 mx-2 mb-6'>
          <img className='image-ppe' src={hand_sewn_mask_icon} alt='Adult Sewn Mask' />
          <p className='title'>{items[1].data.In_inventory}</p>
          <p className='subtitle is-7'>Adult Sewn Masks</p>
        </div>
        <div className='card delivery-breakdown-card px-5 py-4 mx-2 mb-6'>
          <img className='image-ppe' src={hand_sanitizer_icon} alt='Hand Sanitizer' />
          <p className='title'>{items[5].data.In_inventory}</p>
          <p className='subtitle is-7'>Hand Sanitizer</p>
        </div>
        <div className='card delivery-breakdown-card px-5 py-4 mx-2 mb-6'>
          <img className='image-ppe' src={gloves_icon} alt='Gloves' />
          <p className='title'>{items[7].data.In_inventory}</p>
          <p className='subtitle is-7'>Gloves</p>
        </div>
      </div>
    </div>
  )
}

const SuppliesPageTemplate = ({ title, subtitle }) => {
  
  // NEED TO CHANGE TO A FETCH QUERY
  // const newData = fetch("/?no-cache=1", {                                 //eslint-disable-line
  //   method: 'GET',
  //   headers: { },
  // })
  //   .then((data) => {
  //     navigate('/map/success')
  //   })
  //   .catch(error => {
  //     console.log(error)
  //     alert("Error: Please Try Again!");                            //eslint-disable-line
  //   })}

  const data = useStaticQuery(graphql`
    query NotMenuQuery {
      Supplies: allAirtable(
        filter: { table: { eq: "PPE Inventory" } }
      ) {
        nodes {
          data {
            Materials_type
            In_Inventory
            Unit_Price
          }
          recordId
        }
      }
    }
    `)

    if(typeof window !== 'undefined' || typeof document !== 'undefined'){
      if (window.location.pathname.indexOf('admin') > 0) {
        AOS.init({
          disable: false,
        });
      } else AOS.init({disable: false});
    }

    return (
    <div>
      <section className='hero is-primary is-bold is-medium'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className='title' data-aos='fade-down' data-aos-duration='1000'>
                    {title}
                  </h1>
                  <h2 className='subtitle' data-aos='fade-down' data-aos-duration='1000'>
                    {subtitle}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className='container'>
        <SuppliesTotals items={data.Supplies.nodes} />
        <iframe class="airtable-embed" src="https://airtable.com/embed/shrmJx7t0tZUXThEo?backgroundColor=yellow&layout=card&viewControls=on" frameborder="0" onmousewheel="" width="100%" height="533"></iframe>
      </div>
    </div>
  )
}

export default SuppliesPageTemplate
